import React from "react";
import { AboutData } from "@/data";
import Col  from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const AboutTwo = () => {
  const { sectionContent, aboutUsContent } = AboutData;
  return (
    <section className="commonSection ab_agency">
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <span className="text-center">
            <h4 className="sub_title">{aboutUsContent.subTitle}</h4>
            <h1 className="sec_title">{aboutUsContent.title}</h1>
            <p className="sec_desc">{aboutUsContent.text}</p>
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
