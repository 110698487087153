import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const PageBanner = ({ title, name }) => {
  return (
    <section style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          maxHeight: 650,
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        //aspectRatio={3 / 1}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src="../assets/images/hero.avif"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className="banner_content text-center">
          <h2>{title}</h2>
        </div>
      </div>
    </section>
  );
};

export default PageBanner;
