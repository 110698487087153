import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import AboutTwo from "@/components/about-two";
import CallToActionOne from "@/components/call-to-action-one";
import Testimonials from "@/components/testimonials";
import OurValues from "@/components/our-values";
import MeetTheFounder from "@/components/meet-the-founder";
import HeaderTwo from "@/components/header-two";
import MenuContextProvider from "@/context/menu-context";
import FeatureTwo from "@/components/feature-two";

const AboutPage = ({data}) => {
  const { summary } = data.site.siteMetadata.author;
  const title = `About Us`;
  return (
    <MenuContextProvider>
        <Layout PageTitle={title}>
          <SEO title={title} description={summary} />
          <HeaderTwo />
          <PageBanner title="About Us" />
          <AboutTwo />
          <FeatureTwo />
          <OurValues />
          <Testimonials />
          <MeetTheFounder />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
    </MenuContextProvider>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        author {
          summary
        }
      }
    }
  }
`

