import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import SectionTitle from "@/components/section-title";
import { MeetTheFounderData } from "@/data";

const MeetTheFounder = () => {
  const { sectionContent } = MeetTheFounderData;
  return (
    <section className="commonSection meet-the-founder">
      <Container>
        <Row>
          <Col lg={12} className="">
            <SectionTitle data={sectionContent} />
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="sec_desc">
            <p>
              Brenda Maday, M.S., has worked in the Human Resources field in different roles for over twenty years. She has worked as an HR leader in the electronics manufacturing, contract manufacturing, semiconductor, machinery manufacturing, LED, specialty chemical, and non-profit health care industries.
            </p>
            <p>
              Brenda has conducted thousands of interviews throughout her career and has hired several hundreds of employees, covering all job functions and levels. She's been involved in every aspect of the talent acquisition process from sourcing to offer generation to onboarding. In addition, she has built the talent acquisition process from the ground up for small companies and start-ups.
            </p>
            <p>
              Brenda has a Bachelor's degree in English from Northern Illinois University and a Master's degree in Human Resources from Roosevelt University.
            </p>
            <p>
              In her free time, she enjoys traveling, hiking in the mountains, jogging, taking a daily Spanish lesson, and spending time with friends and family.
            </p>
          </Col>
          <Col lg={5}>
            <StaticImage
              style={{ maxWidth: 350, maxHeight: 263 }}
              src="../assets/images/profile-pic.avif"
              alt="Profile Picture"
              formats={["auto", "webp", "avif"]}
              width={350}
              height={263}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MeetTheFounder;
