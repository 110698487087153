import React, { useState } from "react";

import Col  from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import SectionTitle from "@/components/section-title";
import { OurValuesData } from "@/data";

const OurValues = () => {
  const { values, sectionContent } = OurValuesData;
  return (
    <section className="commonSection our-values">
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <SectionTitle data={sectionContent} />
          </Col>
        </Row>
        <Row>
          {values.map((value, index) => {
            return (<Col lg={6} className="text-center" key={index}>
              <h4>{value.title}</h4>
              <p>
                {value.text}
              </p>
            </Col>)
          })}
        </Row>
      </Container>
    </section>
  );
};

export default OurValues;
